import axios from 'axios';
let apiUrl = 'https://api.ringsend.com';
if(process &&process.env&&process.env.NODE_ENV&&process.env.NODE_ENV==='development'){
    apiUrl = 'http://localhost:8033';
}

// let reApiToken =localStorage.getItem('re-api-token')
// let reqHeader = {
//     headers: { Authorization: `Bearer ${reApiToken}` }
// };


const ApiHelper = {
    resume : function(payLoad){
        return axios.post(`${apiUrl}/api/re/signup/resume`,{...payLoad});
    },
    grcVerify : function(payLoad){
        return axios.post(`${apiUrl}/api/re/signup/grc`,{...payLoad});
    },
    capture : function(info){
        return axios.post(`${apiUrl}/api/re/signup/capture`,{...info});
    },
    setPassword : function(info){
        return axios.post(`${apiUrl}/api/re/signup/set-password`,{...info});
    },
    verifyMobile : function(info){
        return axios.post(`${apiUrl}/api/re/signup/verify-mobile`,{...info});
    },
    verifyEmail : function(info){
        return axios.post(`${apiUrl}/api/re/signup/verify-email`,{...info});
    },
    getLocations : function(info){
        return axios.get(`${apiUrl}/api/re/locations/jcp`,{...info});
    },
}

export default ApiHelper;