import { Grid, Paper, TextField, Button, Checkbox, Input, InputLabel, FormControl, FormControlLabel, FormLabel, Slide, RadioGroup, Radio } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import MaskedInput from 'react-text-mask';
import { useEffect } from "react";
import './App.css';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function AccountInfo(props) {

  function handleChange(field,value){
    let newVal=value;
    if(field==='mobile'){
      value = value.replace(/\D/g,'');
      newVal = '';
      for (var i = 0; i < value.length; i++) {
        if(i===0){newVal=newVal+`(${value[i]}`}
        else if(i<=2){newVal=newVal+value[i]}
        else if(i===3){newVal=newVal+`) ${value[i]}`}
        else if(i<=5){newVal=newVal+value[i]}
        else if(i===6){newVal=newVal+`-${value[i]}`}
        else if(i<=9){newVal=newVal+value[i]}
      }
    }
    props.setState(prev=>{
      let ts = {...prev}
      ts[field]=newVal;
      return ts;
    })
  }

  function setPath(p){
    props.setState(prev=>{
        const ts = {...prev};
        ts.path=p;
        if(p==='Personal'){
          ts.proType=null;
        }
        // ts.step=1;
        // ts.location.pathname='/personal-info';
        // ts.loc='/personal-info';
        // ts.direction='left';
        return ts;
    })
  }

  useEffect(()=>{},[props.state.showCodeVerify])

  return (
      <Slide direction={props.state.direction} in={true}>
      <div className="app-container">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className="card card-full">
            <h2>Account Info</h2>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}><h2 style={{textAlign:'center'}}>Select the account type that best meets your needs</h2></Grid> */}
              <Grid item xs={12}>
                  <div className="card-parent">
                      
                          <Paper id="proCard" className="account-select-card">
                          <img src="pro-icon.png" className="account-select-icon"/>
                          <div className="account-select-path">Pro Account</div>
                          I'm making purchases for my business
                          <hr/>
                          <ul>
                              <li>Organize projects w/job codes</li>
                              <li>Manage tax-exempt projects</li>
                              <li>Access our contractor desk</li>
                          </ul>
                          <div className="select-button" onClick={()=>{setPath('Pro');}}>SELECT</div>
                          {props.state.path==='Pro'?<CheckCircle color="primary"></CheckCircle>:''}
                          </Paper>

                          
                      
                      <div className="account-select-or">--- OR ---</div>
                      
                          <Paper id="personalCard" className="account-select-card">
                          <img src="diy-icon.png" className="account-select-icon"/>
                          <div className="account-select-path">Personal Account</div>
                          I'm shopping for myself
                          <hr/>
                          <ul>
                              <li>Enjoy faster checkout</li>
                              <li>Favorite product and save lists</li>
                              <li>Designate authorized purchasers</li>
                          </ul>
                          <div className="select-button" onClick={()=>{setPath('Personal');}}>SELECT</div>
                          {props.state.path==='Personal'?<CheckCircle color="primary"></CheckCircle>:''}
                          </Paper>
                      
                  </div>
              </Grid>
              <Grid item xs={12}>
                  {props.state.path==='Pro'?<FormControl style={{flexDirection:'row'}}>
                  <FormLabel style={{marginRight:'10px',marginTop:'12px'}} id="account-type-label">Pro Type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="account-type-label"
                    name="account-type-group"
                    defaultValue={props.state.proType?props.state.proType:'consumer'}
                    onChange={(e)=>{handleChange('proType',e.target.value)}}
                    
                  >
                    
                        <FormControlLabel value="painter" control={<Radio />} label="Painter" />
                        <FormControlLabel value="contractor" control={<Radio />} label="Contractor" />
                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                      
                      
                      
                    
                    
                    
                  </RadioGroup>
                </FormControl>:''}
                {props.state.formErrors['proType']?<div style={{color:'red'}}>* {props.state.formErrors['proType']}</div>:''}
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl style={{flexDirection:'row'}}>
                  <FormLabel style={{marginRight:'10px',marginTop:'12px'}} id="account-type-label">Account Type</FormLabel>
                  <RadioGroup
                  
                    aria-labelledby="account-type-label"
                    name="account-type-group"
                    defaultValue={props.state.proType?props.state.proType:'consumer'}
                    onChange={(e)=>{handleChange('proType',e.target.value);setPath(e.target.value)}}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={9}>
                        <FormControlLabel value="painter" control={<Radio />} label="Painter" labelPlacement="bottom"/>
                        <FormControlLabel value="contractor" control={<Radio />} label="Contractor" labelPlacement="bottom"/>
                        <FormControlLabel value="other" control={<Radio />} label="Other" labelPlacement="bottom"/>
                      </Grid>
                      <Grid item xs={3}>
                      <FormControlLabel value="consumer" control={<Radio />} label="Consumer" labelPlacement="bottom"/>
                      </Grid>
                    </Grid>
                    
                    
                    
                  </RadioGroup>
                </FormControl>
              </Grid> */}
              
              {/* ------------------- */}
              {/* <Grid item xs={12} lg={4}>
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['firstName']?props.state.formErrors['firstName']:''}
                error={props.state.formErrors['firstName']?true:false}
                label="First Name" required variant="outlined" size="small" defaultValue={props.state.firstName} onChange={(e)=>{handleChange('firstName',e.target.value)}}/>
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['middleName']?props.state.formErrors['middleName']:''}
                error={props.state.formErrors['middleName']?true:false}
                label="Middle Name" variant="outlined" size="small" defaultValue={props.state.middleName} onChange={(e)=>{handleChange('middleName',e.target.value)}}/>
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['lastName']?props.state.formErrors['lastName']:''}
                error={props.state.formErrors['lastName']?true:false}
                label="Last Name" required variant="outlined" size="small" defaultValue={props.state.lastName} onChange={(e)=>{handleChange('lastName',e.target.value)}}/>
              </Grid> */}
              {/* ------------------- */}
              {props.state.path==='Pro'?
              <Grid item xs={12}>
                <TextField style={{width:"100%"}} placeholder="Enter business or employer name"  autoComplete="organization"
                helperText={props.state.formErrors['businessName']?props.state.formErrors['businessName']:''}
                error={props.state.formErrors['businessName']?true:false}
                label="Business Name" required variant="outlined" size="small" defaultValue={props.state.businessName} onChange={(e)=>{handleChange('businessName',e.target.value)}}/>
              </Grid>:''}
              {/* <Grid item xs={12} lg={12}>
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['existingAcctNum']?props.state.formErrors['existingAcctNum']:''}
                error={props.state.formErrors['existingAcctNum']?true:false}
                label="Previous Johnson Paint Account" variant="outlined" size="small" defaultValue={props.state.existingAcctNum} onChange={(e)=>{handleChange('existingAcctNum',e.target.value)}}/>
              </Grid> */}
              {/* ------------------- */}
              {/* <Grid item xs={12}>
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['email']?props.state.formErrors['email']:''}
                error={props.state.formErrors['email']?true:false}
                label="Email Address" required type="email" inputMode="email" variant="outlined" size="small" defaultValue={props.state.email} onChange={(e)=>{handleChange('email',e.target.value)}}/>
              </Grid> */}
              {/* ------------------- */}
              {/* <Grid item xs={8}>
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['mobile']?props.state.formErrors['mobile']:''}
                error={props.state.formErrors['mobile']?true:false}
                label="Mobile Phone" inputMode="tel" required variant="outlined" size="small" value={props.state.mobile} defaultValue={props.state.mobile} onChange={(e)=>{handleChange('mobile',e.target.value)}}/>
              </Grid>
              <Grid item xs={4}>
              <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.state.verifyMobile}
                    onChange={(e)=>{
                      handleChange('verifyMobile',e.target.checked)
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={<div>Verify Mobile<br/></div>}
              />
              </div><div><span className="disclaimer">Msg rate may apply</span></div>
              </Grid> */}
            </Grid>
            
            </Paper>
          </Grid>
      </Grid>
      </div>
      </Slide>
  );
}

export default AccountInfo;
