import React, {useState,useEffect} from "react";
import { Grid, Paper,  Slide, TextField, Button } from "@material-ui/core";
import ApiHelper from "./helpers/apiHelper";
import './App.css';

function ThankYou(props) {

  function handleChange(field,value){
    props.setState(prev=>{
      let ts = {...prev}
      ts[field]=value;
      return ts;
    })
  }

  return (
      <Slide direction={props.state.direction} in={true}>
      <div className="app-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className="card card-full">
            <h2>Thank You!</h2>
            <Grid container spacing={2}>

              <Grid item xs={12} style={{textAlign:'left'}}>
              {props.state.requestCredit?(
                <p>Please check your inbox for an email with a credit application.<br/><br/>After you complete and return the application, allow 7-10 business days for approval, after which your account will be created, and you will receive an email with your new account code.</p>
              ):(
                <p>Please allow 1-2 business days for creation of your in-store account. You'll receive an email with your new account code which you can mention at the counter when purchasing at any Johnson Paint location.</p>
              )}
              </Grid>
              {props.state.requestOnlineAccount?(
                
                  <Grid item xs={12} style={{textAlign:'left'}}>
                    <strong>During signup, you indicated you wanted an account on ringsend.com to view invoices and make payments.</strong> Please check your inbox for an email to set your password on ringsend.com.
                  </Grid>
                 
              ):''}
            
            
            </Grid>
            </Paper>
          </Grid>
      </Grid>
      </div>
      </Slide>
  );
}

export default ThankYou;
