import { useState } from "react";
import { Grid, Paper, TextField, Slide } from "@material-ui/core";
import {Visibility,VisibilityOff, CheckCircle } from "@material-ui/icons";
import './App.css';

function Password(props) {
    const [showPassword,setShowPassword]=useState(false);

    function handleChange(field,value){
        props.setState(prev=>{
            let ts = {...prev}
            ts[field]=value;
            return ts;
        })
    }

    return (
        <Slide direction={props.state.direction} in={true}>
        <div className="app-container">
        <Grid container spacing={4}>
            <Grid item xs={12}>
            <Paper className="card card-full">
            <h2>Create a Password</h2>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['password']?props.state.formErrors['password']:''}
                error={props.state.formErrors['password']?true:false}
                type={showPassword?'text':'password'} label="Password" required variant="outlined" size="small" defaultValue={props.state.password} onChange={(e)=>{handleChange('password',e.target.value)}}/>
                </Grid>
                <Grid item xs={2} style={{textAlign:'left',paddingTop:'15px'}}>
                {showPassword?<Visibility color="primary" className="clickableIcon" onClick={()=>{setShowPassword(false)}}/>:<VisibilityOff color="primary" className="clickableIcon" onClick={()=>{setShowPassword(true)}}/>}
                </Grid>
                <Grid item xs={7} sm={4} md={4} lg={3} style={{paddingBottom:'20px',color:'#999',textAlign:'left',paddingLeft:'12px',fontSize:'12px'}}>
                    Minimum 8 characters
                </Grid>
               <Grid item xs={2} style={{textAlign:'left',paddingTop:'6px',paddingLeft:'0px'}}>
               {props.state.password.length&&props.state.password.length>7?<CheckCircle style={{color:'green'}}/>:''}
               </Grid>
            </Grid>
            
            </Paper>
            </Grid>
        </Grid>
        </div>
        </Slide>
    );
}

export default Password;
