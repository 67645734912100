export function checkApplicationErrors(showNar,narChecked,fieldErrors,props,setStep,stateCodes,stateNames,isValid){
    if(showNar && !narChecked){
        fieldErrors['nar']=`Please confirm you're not a robot`;
    }
    if(props.history.location.pathname==='/'){
        checkPersonalInfo(fieldErrors,props)
    }else if(props.history.location.pathname==='/account-info'){
        checkPersonalInfo(fieldErrors,props);
        if(Object.keys(fieldErrors).length){
            goBack(setStep,props,1)
        }else{
            checkBusinessInfo(fieldErrors,props)
        }
    }else if(props.history.location.pathname==='/in-store-acct'){
        checkPersonalInfo(fieldErrors,props)
        if(Object.keys(fieldErrors).length){
          goBack(setStep,props,2)
        }else{
            checkBusinessInfo(fieldErrors,props);
            if(Object.keys(fieldErrors).length){
                goBack(setStep,props,1)
            }else{
                checkAddressInfo(fieldErrors,props,stateCodes,stateNames)
            }
        }
    }else if(props.history.location.pathname==='/cc-info'){
        checkPersonalInfo(fieldErrors,props)
        if(Object.keys(fieldErrors).length){
          goBack(setStep,props,3)
        }else{
            checkBusinessInfo(fieldErrors,props);
            if(Object.keys(fieldErrors).length){
                goBack(setStep,props,2)
            }else{
                checkAddressInfo(fieldErrors,props,stateCodes,stateNames)
                if(Object.keys(fieldErrors).length){
                    goBack(setStep,props,1)
                }else{
                    checkCcInfo(fieldErrors,props,stateCodes)
                }
            }
        }
    }else if(props.history.location.pathname==='/account-options'){
        checkPersonalInfo(fieldErrors,props)
        if(Object.keys(fieldErrors).length){
          goBack(setStep,props,4)
        }else{
            checkBusinessInfo(fieldErrors,props);
            if(Object.keys(fieldErrors).length){
                goBack(setStep,props,3)
            }else{
                checkAddressInfo(fieldErrors,props,stateCodes,stateNames)
                if(Object.keys(fieldErrors).length){
                    goBack(setStep,props,2)
                }else{
                    checkCcInfo(fieldErrors,props,stateCodes)
                    if(Object.keys(fieldErrors).length){
                        goBack(setStep,props,1)
                    }else{
                        checkOptionsInfo(fieldErrors,props,stateCodes,isValid)
                    }
                }
            }
        }
    }
    return(fieldErrors)
}

function goBack(setStep,props,backSteps){
    setStep(props.state.step-backSteps,'right');
    props.history.push(props.state.steps[props.state.step-backSteps].url);
}

function checkPersonalInfo(fieldErrors,props){
    if(!props.state.firstName.trim()){
        fieldErrors['firstName']='Please enter your first name';
    }
    if(props.state.firstName.replace(/\s/g,'').match(/^\d+$/)){
        fieldErrors['firstName']='Name cannot be a number';
    }
    if(!props.state.lastName.trim()){
        fieldErrors['lastName']='Please enter your last name';
    }
    if(props.state.lastName.replace(/\s/g,'').match(/^\d+$/)){
        fieldErrors['lastName']='Name cannot be a number';
    }
    if(!props.state.email.trim()||!props.state.email.match(/.+@.+\..+/)){
        fieldErrors['email']='Please enter a valid email address';
    }
    if(!props.state.mobile.trim()||!props.state.mobile.replace(/\D/g,'').match(/\d{10}/)){
        fieldErrors['mobile']='Valid mobile phone required';
    }
}

function checkBusinessInfo(fieldErrors,props){
    if(props.state.path==="Pro"){
        if(!props.state.businessName.trim()){
          fieldErrors['businessName']='Please enter your name or business name';
        }
        if(props.state.businessName.replace(/\s/g,'').match(/^\d+$/)){
          fieldErrors['businessName']='Business name cannot be a number';
        }
        if(!props.state.proType){
            fieldErrors['proType']='Please pick a pro category';
        }
    }
}

function checkAddressInfo(fieldErrors,props,stateCodes){
    if(!props.state.billingAddress.trim().match(/\w+\s+\w\w+/)){
        fieldErrors['billingAddress']='Please enter your billing address';
    }
    if(!props.state.billingCity.trim()){
        fieldErrors['billingCity']='Please enter your billing city';
    }
    if(props.state.billingCity.replace(/\s/g,'').match(/^\d+$/)){
        fieldErrors['billingCity']='City cannot be a number';
    }
    if(!stateCodes.includes(props.state.billingState)){
        fieldErrors['billingState']='2-letter state required';
    }
    if(!props.state.billingZip.trim()||(!props.state.billingZip.replace(/\D/g,'').match(/^\d{5}$/)&&!props.state.billingZip.replace(/\D/g,'').match(/^\d{9}$/))){
        fieldErrors['billingZip']='Please enter a valid zip code';
    }
    if(!props.state.nearestLocs.length){
        fieldErrors['FindStores']='Please find a preferred store'
    }
}

function checkOptionsInfo(fieldErrors,props,stateCodes,isValid){
    if(!props.state.accountCreationAuthorized){
        fieldErrors['accountCreationAuthorized']='Please authorize by checking the box';
    }
    if(!props.state.accountCreationSignature){
        fieldErrors['accountCreationSignature']='Please enter your name or initials';
    }
    if(props.state.payByCheck){
        if(!stateCodes.includes(props.state.licenseState)){
          fieldErrors['licenseState']='Please enter a valid U.S. state for your license';
        }else{
            if(!props.state.licenseNum.trim()){
                fieldErrors['licenseNum']=`Please enter a valid ${props.state.licenseState} driver's license number`;
            }else if(props.state.licenseNum.trim()&&props.state.licenseState){
                if(!isValid(props.state.licenseState,props.state.licenseNum)){
                  fieldErrors['licenseNum']=`Please enter a valid ${props.state.licenseState} driver's license number`;
                }
            }
        }
    }
}

function checkCcInfo(fieldErrors,props,stateCodes){
    if(props.state.requestCardOnFile){
        if(!props.state.ccName ||!props.state.ccName.trim().match(/\w+/)){
            fieldErrors['ccName']='Please enter the name on your credit card';
        }
        if(!props.state.ccNum.trim().match(/\d{15,16}/)){
            fieldErrors['ccNum']='Please enter your credit card number';
        }
        if(!props.state.ccExpiration.trim().match(/^\d{2}\/\d{2}$/)&&!props.state.ccExpiration.trim().match(/^\d{2}\/\d{4}$/)){
            fieldErrors['ccExpiration']='Please enter your credit card expiration date';
        }
        if(!props.state.ccCode.trim().match(/\d{3,4}/)){
            fieldErrors['ccCode']='Please enter your credit card CVV code';
        }
        if(!props.state.ccAddress.trim()){
            fieldErrors['ccAddress']='Please enter your credit card billing address';
        }
        if(!props.state.ccCity.trim()){
            fieldErrors['ccCity']='Please enter your credit card billing city';
        }
        if(!stateCodes.includes(props.state.ccState)){
            fieldErrors['ccState']='Please enter your credit card billing state';
        }
        if(!props.state.ccZip.trim().match(/\d{5}/)){
            fieldErrors['ccZip']='Please enter your credit card billing zip code';
        }
        if(!props.state.ccEmail.trim()||!props.state.ccEmail.match(/.+@.+\..+/)){
            fieldErrors['ccEmail']='Please enter the cardholder email address';
        }
    }
}