import { Grid, Paper, TextField, Button, Checkbox, Input, InputLabel, FormControl, FormControlLabel, FormLabel, Slide, RadioGroup, Radio, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import MaskedInput from 'react-text-mask';
import React, {useState,useEffect} from "react";
import {withRouter} from "react-router-dom";
import ApiHelper from "./helpers/apiHelper";
import './App.css';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function ContactInfo(props) {

  const [resumeEmailDialog,setResumeEmailDialog]=useState(false);

  function handleChange(field,value){
    let newVal=value;
    if(field==='mobile'){
      value = value.replace(/\D/g,'');
      if(value.match(/^1.*$/)){value=value.replace(/^1(.*)$/,"$1")}
      newVal = '';
      for (var i = 0; i < value.length; i++) {
        if(i===0){
          newVal=newVal+`(${value[i]}`
        }
        else if(i<=2){newVal=newVal+value[i]}
        else if(i===3){newVal=newVal+`) ${value[i]}`}
        else if(i<=5){newVal=newVal+value[i]}
        else if(i===6){newVal=newVal+`-${value[i]}`}
        else if(i<=9){newVal=newVal+value[i]}
      }
    }
    props.setState(prev=>{
      let ts = {...prev}
      ts[field]=newVal;
      return ts;
    })
  }

  function setPath(p){
    let myPath='Personal';
    if(p!=='consumer'){
      myPath='Pro'
    }
    props.setState(prev=>{
        const ts = {...prev};
        ts.path=myPath;
        if(p==='consumer'){
          ts.proType=null;
        }
        // ts.step=1;
        // ts.location.pathname='/personal-info';
        // ts.loc='/personal-info';
        // ts.direction='left';
        return ts;
    })
  }


  function resumeApp(){
    let emailAddy='';
    if(document.getElementById('emailAddy')&&document.getElementById('emailAddy').value){
      emailAddy=document.getElementById('emailAddy').value;
    }
    ApiHelper.resume({
      code:props.state.resume,
      email:emailAddy
    })
    .then(response=>{
      if(response.data&&response.data.error){
        alert(response.data.error)
      }else if(response.data&&response.data.state){
        props.setState(prev=>{
          const ts= response.data.state;
          ts.direction='left';
          // for(let st=0;st<ts.step+1;st++){
          //   props.history.push(ts.steps[st].url);
          // }
          return ts;  
        })
        setResumeEmailDialog(false)
      }
    })
  }

  useEffect(()=>{
    if(props.state.resume&&!props.state.email){
      setResumeEmailDialog(true)
    }
  },[]);
  useEffect(()=>{},[props.state.showCodeVerify]);

  return (
      <React.Fragment>
      <Slide direction={props.state.direction} in={true}>
      <div className="app-container">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className="card card-full">
            <h2>Contact Information</h2>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="account-type-label">Account Type</FormLabel>
                  <RadioGroup
                  row
                    aria-labelledby="account-type-label"
                    name="account-type-group"
                    defaultValue={props.state.proType?props.state.proType:'consumer'}
                    onChange={(e)=>{handleChange('proType',e.target.value);setPath(e.target.value)}}
                  >
                    <FormControlLabel value="painter" control={<Radio />} label="Painter" />
                    <FormControlLabel value="contractor" control={<Radio />} label="Contractor" />
                    <FormControlLabel value="consumer" control={<Radio />} label="Consumer" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
              
              {/* ------------------- */}
              <Grid item xs={12} lg={6}>
                <TextField style={{width:"100%"}} autoComplete="given-name" autoFocus
                helperText={props.state.formErrors['firstName']?props.state.formErrors['firstName']:''}
                error={props.state.formErrors['firstName']?true:false}
                label="First Name" required variant="outlined" size="small" value={props.state.firstName} onChange={(e)=>{handleChange('firstName',e.target.value)}}/>
              </Grid>
              {/* <Grid item xs={12} lg={4}>
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['middleName']?props.state.formErrors['middleName']:''}
                error={props.state.formErrors['middleName']?true:false}
                label="Middle Name" variant="outlined" size="small" defaultValue={props.state.middleName} onChange={(e)=>{handleChange('middleName',e.target.value)}}/>
              </Grid> */}
              <Grid item xs={12} lg={6}>
                <TextField style={{width:"100%"}} autoComplete="family-name"
                helperText={props.state.formErrors['lastName']?props.state.formErrors['lastName']:''}
                error={props.state.formErrors['lastName']?true:false}
                label="Last Name" required variant="outlined" size="small" value={props.state.lastName} onChange={(e)=>{handleChange('lastName',e.target.value)}}/>
              </Grid>
              {/* ------------------- */}
              {/* {props.state.path==='Pro'?
              <Grid item xs={12}>
                <TextField style={{width:"100%"}} placeholder="Enter business or employer name"  autocomplete="organization"
                helperText={props.state.formErrors['businessName']?props.state.formErrors['businessName']:''}
                error={props.state.formErrors['businessName']?true:false}
                label="Business Name" required variant="outlined" size="small" defaultValue={props.state.businessName} onChange={(e)=>{handleChange('businessName',e.target.value)}}/>
              </Grid>:''} */}
              <Grid item xs={12} lg={4}>
                <TextField style={{width:"100%"}} autoComplete="email"
                helperText={props.state.formErrors['email']?props.state.formErrors['email']:''}
                error={props.state.formErrors['email']?true:false}
                label="Email Address" required type="email" inputMode="email" variant="outlined" size="small" value={props.state.email} onChange={(e)=>{handleChange('email',e.target.value)}}/>
              </Grid>
              <Grid item xs={8} lg={4}>
                <TextField style={{width:"100%"}} autoComplete="tel"
                helperText={props.state.formErrors['mobile']?props.state.formErrors['mobile']:''}
                error={props.state.formErrors['mobile']?true:false}
                label="Mobile Phone" inputMode="tel" required variant="outlined" size="small" value={props.state.mobile} onChange={(e)=>{handleChange('mobile',e.target.value)}}/>
              </Grid>
              <Grid item xs={4}>
              <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.state.verifyMobile}
                    onChange={(e)=>{
                      handleChange('verifyMobile',e.target.checked)
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={<div>Verify Mobile<br/></div>}
              />
              </div><div><span className="disclaimer">Msg rate may apply</span></div>
              </Grid>
              {/* ------------------- */}
              {/* <Grid item xs={12} lg={8} style={{textAlign:'left'}}> 
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['licenseNum']?props.state.formErrors['licenseNum']:''}
                error={props.state.formErrors['licenseNum']?true:false}
                label="Driver's License Number" variant="outlined" size="small" defaultValue={props.state.licenseNum} onChange={(e)=>{handleChange('licenseNum',e.target.value)}}/><br/>
                <span style={{fontSize:'9px',fontStyle:'italic'}}>License info is required if you want to pay by check</span>
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['licenseState']?props.state.formErrors['licenseState']:''}
                error={props.state.formErrors['licenseState']?true:false}
                label="License State" variant="outlined" size="small" defaultValue={props.state.licenseState}
                onKeyUp={(e)=>{
                  let val = e.target.value.toUpperCase();
                  if(!e.key.match(/[a-zA-Z]|Backspace|ArrowLeft|ArrowRight/)||val.match(/^\w{3}/)){e.target.value=val.slice(0,val.length-1)}
                }}
                onChange={(e)=>{
                  e.target.value=e.target.value.toUpperCase();
                  handleChange('licenseState',e.target.value)
                  }}/>
              </Grid> */}
            </Grid>
            
            </Paper>
          </Grid>
      </Grid>
      </div>
      </Slide>
      <Dialog 
          open={resumeEmailDialog}
          onClose={()=>{setResumeEmailDialog(false)}}
        >
        <DialogTitle>Enter Your Email to Resume</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email address you used when you started your account application
          </DialogContentText>
          <TextField
            variant="outlined"
            autoFocus
            margin="dense"
            id="emailAddy"
            label="Email"
            type="email"
            inputMode="email"
            autoComplete="email"
            fullWidth
            onKeyUp={(e)=>{
              if(e.keyCode===13){resumeApp()}
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={resumeApp} color="primary">
            Resume
          </Button>
        </DialogActions>
      </Dialog>
      </React.Fragment>
  );
}

export default withRouter(ContactInfo);
