import React, {useEffect} from "react";
import {withRouter, Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import {KeyboardArrowLeft} from "@material-ui/icons";

function BackButton(props) {
    
    useEffect(()=>{
        if(props.state.loc!==props.location.pathname){
            let steps = props.state.steps;
            if(props.state.path==='Personal'){steps=steps.filter(step=>!step.pro).map(step=>step)}
            let step=0;
            steps.forEach((s,i)=>{
                if(s.url===props.location.pathname){step=i}
            });
            props.setState(prev=>{
                let ts = {...prev};
                ts.step=step;
                ts.loc=steps[step].url;
                ts.location.pathname = steps[step].url;
                return ts;
            })
        }
    },[props.location.pathname])

    function setStep(s,d){
        let steps = props.state.steps;
        if(props.state.path==='Personal'){steps=steps.filter(step=>!step.pro).map(step=>step)}
        props.setState(prev=>{
          let ts = {...prev};
          ts.step=s;
          ts.loc=steps[s].url;
          ts.location.pathname = steps[s].url;
          ts.direction=d;
          return ts;
        })
      }
    
    function getBackButton(){
        let steps = props.state.steps;
        
        if(props.state.step>0){
            return(
            <React.Fragment>
            &nbsp;<Button size="small" onClick={()=>{
                setStep(props.state.step-1,'right');
                //props.history.push(steps[props.state.step-1].url);
                props.history.goBack();
            }}>
            <KeyboardArrowLeft />
            Back
            </Button>
            </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                &nbsp;<Button size="small"  disabled>
                <KeyboardArrowLeft />
                Back
                </Button>
                </React.Fragment>
            )
        }
    }

    return(
        <React.Fragment>
        {getBackButton()}
        </React.Fragment>
    );
};

export default withRouter(BackButton);