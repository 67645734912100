import { Grid, Paper, TextField, Checkbox, FormControlLabel, Slide, Typography,IconButton, Dialog, DialogTitle, DialogActions, DialogContent, Button } from "@material-ui/core";
import { AddCircle, HelpOutlineRounded, Close as CloseIcon } from "@material-ui/icons";
import React, {useState} from "react";
import './App.css';
import JobAccount from "./JobAccount";
import AuthChargeName from "./AuthChargeName";

function AccountOptions(props) {

  const [ttip,setTtip] = useState({open:false,title:'',text:''});

  function handleChange(field,value){
    props.setState(prev=>{
      let ts = {...prev}
      ts[field]=value;
      return ts;
    })
  }

  function addJobAccount(){
    props.setState(prev=>{
      let ts = {...prev}
      ts.jobAccounts.unshift({
        name:'',
        address1:'',
        address2:'',
        city:'',
        state:'',
        zip:'',
        taxable:true,
        requirePo:false
      });
      return ts;
    })
  }

  function addAuthToChargeName(){
    props.setState(prev=>{
      let ts = {...prev}
      ts.authorizedToCharge.unshift({
        firstName:'',
        lastName:''
      });
      return ts;
    })
  }

  return (
      <Slide direction={props.state.direction} in={true}>
      <div className="app-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className="card card-full">
            <h2>Account Options</h2>
            <Grid container spacing={2}>
                
                <React.Fragment>
                <Grid item xs={1} style={{textAlign:'left'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.state.emailInvoices}
                      onChange={(e)=>{
                        handleChange('emailInvoices',e.target.checked)
                      }}
                      name="checkedEdu"
                      color="primary"
                    />
                  }
                />
                </Grid>
                <Grid item xs={11} style={{textAlign:"left",paddingTop:"19px"}}>
                I want to receive invoices via email
                </Grid>
                </React.Fragment>

                {props.state.path==='Pro'?(
                  <React.Fragment>
                    <Grid item xs={1} style={{textAlign:'left'}}>
                      <FormControlLabel
                            control={
                            <Checkbox
                                checked={props.state.requirePO}
                                onChange={(e)=>{
                                handleChange('requirePO',e.target.checked)
                                }}
                                name="checkedPO"
                                color="primary"
                            />
                            }
                        />
                    </Grid>
                    <Grid item xs={11} style={{textAlign:"left",paddingTop:"19px"}}>
                    <span>I would like to require a <a onClick={()=>{
                  setTtip(prev=>{
                    let ts={...prev}
                    ts={open:true,title:'Purchase Orders',text:`Add the PO# during checkout online or mention it to your sales associate when making an in-store purchase. We will add the PO# to your invoice.`}
                    return(ts)
                  })
                  }} href="#">purchase order</a> for all transactions</span>
                    </Grid>
                  </React.Fragment>
                ):''}

                <Grid item xs={1} style={{textAlign:'left'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.state.requestCredit}
                      onChange={(e)=>{
                        handleChange('requestCredit',e.target.checked)
                      }}
                      name="checkedCredit"
                      color="primary"
                    />
                  }
                  label=""
                />
                </Grid>
                <Grid item xs={11} style={{textAlign:"left",paddingTop:"19px"}}>
                {props.state.path==='Pro'?(<span>I would like <a onClick={()=>{
                  setTtip(prev=>{
                    let ts={...prev}
                    ts={open:true,title:'Trade Credit',text:'Buy now and pay later with a credit account. Bills are due and payable within 15 days after the date of billing and are past due after 30 days.'}
                    return(ts)
                  })
                  }} href="#">trade credit</a> <span style={{fontSize:"11px",paddingLeft:"11px"}}> Your account will <em>not</em> be created until you complete and submit the credit form.</span></span>):(<span>I would like <a onClick={()=>{
                    setTtip(prev=>{
                      let ts={...prev}
                      ts={open:true,title:'Store Credit',text:'Buy now and pay later with a credit account. Bills are due and payable within 15 days after the date of billing and are past due after 30 days.'}
                      return(ts)
                    })
                    }} href="#">store credit</a> <span style={{fontSize:"11px"}}> Your account will <em>not</em> be created until you complete and submit the credit form.</span></span>)}
                    
                    {/*Only show if credit is requested*/}
                {props.state.requestCredit?(
                    <p style={{fontSize:'small',fontStyle:'italic',textAlign:'left'}}>We will email a Credit Application. Your account will be created after we receive and approve your completed application.</p>
                ):''}
                </Grid>

                
                <Grid item xs={1} style={{textAlign:'left'}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.state.requestOnlineAccount}
                        onChange={(e)=>{
                          handleChange('requestOnlineAccount',e.target.checked)
                        }}
                        name="checkedRequestOnlineAccount"
                        color="primary"
                      />
                    }
                    label=""
                  />
                  </Grid>
                  <Grid item xs={11} style={{textAlign:"left",paddingTop:"19px"}}>
                  I want an account on ringsend.com to buy materials, view invoices and make payments.
                  </Grid>
                  
                <Grid item xs={1} style={{textAlign:'left'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.state.payByCheck}
                      onChange={(e)=>{
                        handleChange('payByCheck',e.target.checked)
                      }}
                      name="checkedPayByCheck"
                      color="primary"
                    />
                  }
                  label=""
                />
                </Grid>
                <Grid item xs={11} style={{textAlign:"left",paddingTop:"19px"}}>
                I want to pay with checks.
                    {/*Only show if pay with check is requested*/}
                {props.state.payByCheck?(
                    <Grid container spacing={1} style={{marginTop:"10px"}}>
                      <Grid item xs={12} lg={8} style={{textAlign:'left'}}> 
                        <TextField style={{width:"100%"}}
                        helperText={props.state.formErrors['licenseNum']?props.state.formErrors['licenseNum']:''}
                        error={props.state.formErrors['licenseNum']?true:false}
                        label="Driver's License Number" variant="outlined" size="small" defaultValue={props.state.licenseNum} onChange={(e)=>{handleChange('licenseNum',e.target.value)}}/><br/>
                        <span style={{fontSize:'9px',fontStyle:'italic'}}>License info is required if you want to pay by check</span>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <TextField style={{width:"100%"}}
                        helperText={props.state.formErrors['licenseState']?props.state.formErrors['licenseState']:''}
                        error={props.state.formErrors['licenseState']?true:false}
                        label="License State" variant="outlined" size="small" defaultValue={props.state.licenseState}
                        onKeyUp={(e)=>{
                          let val = e.target.value.toUpperCase();
                          if(!e.key.match(/[a-zA-Z]|Backspace|ArrowLeft|ArrowRight/)||val.match(/^\w{3}/)){e.target.value=val.slice(0,val.length-1)}
                        }}
                        onChange={(e)=>{
                          e.target.value=e.target.value.toUpperCase();
                          handleChange('licenseState',e.target.value)
                          }}/>
                      </Grid>
                    </Grid>
                ):''}
                </Grid>
                
                

                {/*Only show if credit is requested*/}
                {/* {props.state.requestCredit?(

                <React.Fragment>
                  <Grid item style={{textAlign:"right"}} xs={10} md={6}>
                    <Typography style={{fontWeight:600}} variant='body2'>
                      Add names of people who are authorized to charge to your account:
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={2} style={{textAlign:'left'}}>
                    <IconButton size="small" variant="contained" color="primary" onClick={addAuthToChargeName}>
                      <AddCircle/>
                    </IconButton>
                  </Grid>

                  {props.state.authorizedToCharge.map((ac,idx)=>(
                  <Grid item xs={12} >
                    <AuthChargeName name={props.state.authorizedToCharge[idx]} state={props.state} setState={props.setState} index={idx}/>
                  </Grid>
                  ))}

                </React.Fragment>):''} */}

                <Grid item xs={1} style={{textAlign:'left'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.state.accountCreationAuthorized}
                      onChange={(e)=>{
                        handleChange('accountCreationAuthorized',e.target.checked)
                        props.state.formErrors.accountCreationAuthorized=''
                      }}
                      name="checkedAccountCreationAuthorized"
                      color="primary"
                      required
                    />
                  }
                  label=""
                />
                </Grid>
                <Grid item xs={11} style={{textAlign:"left",paddingTop:"19px"}}>
                <span>I hereby <a onClick={()=>{
                  setTtip(prev=>{
                    let ts={...prev}
                    ts={open:true,title:'Authorization',text:`By entering my full name below, I hereby acknowledge that I am personally responsible for all purchases incurred on this account, including those made by authorized users. I will remain responsible for all purchases made on this account until I notify Ring's End, Inc. and any of its subsidiary companies in writing regarding any changes to the information I have provided.`}
                    return(ts)
                  })
                  }} href="#">authorize</a> the creation of this account</span> <span>*</span><br/> {props.state.formErrors.accountCreationAuthorized?<span style={{color:'red',fontStyle:'italic',fontSize:'smaller'}}>{props.state.formErrors.accountCreationAuthorized}</span>:''}
                </Grid>

                {/*Only show if acct creation is authorized*/}
                {props.state.accountCreationAuthorized?(
                  <React.Fragment>
                    <Grid item xs={12} style={{fontSize:'small',fontStyle:'italic',textAlign:'left'}}>
                      <TextField style={{width:"100%"}}
                helperText={props.state.formErrors['accountCreationSignature']?props.state.formErrors['accountCreationSignature']:''}
                error={props.state.formErrors['accountCreationSignature']?true:false}
                label="Full Name/Signature" required variant="outlined" size="small" defaultValue={props.state.accountCreationSignature} onChange={(e)=>{handleChange('accountCreationSignature',e.target.value)}}/>
                    </Grid>
                    {/* <Grid item xs={5} style={{fontSize:'small',fontStyle:'italic',textAlign:'left'}}>
                    <TextField style={{width:"100%"}}
              helperText={props.state.formErrors['accountCreationSignatureDate']?props.state.formErrors['accountCreationSignatureDate']:''}
              error={props.state.formErrors['accountCreationSignatureDate']?true:false}
              label="Date" required disabled variant="outlined" size="small" defaultValue={props.state.accountCreationSignatureDate} onChange={(e)=>{handleChange('accountCreationSignatureDate',e.target.value)}}/>
                  </Grid> */}
                  </React.Fragment>
                ):''}
              
              </Grid>
            </Paper>
          </Grid>
      </Grid>
      {/* <pre>{JSON.stringify(props.state,null,2)}</pre> */}
      <Dialog fullWidth open={ttip.open}>
        <DialogTitle>{ttip.title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>{setTtip(prev=>{
            let ts={...prev}
            ts={open:false,title:'',text:''}
            return(ts)
          })}}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#777',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>{ttip.text}</DialogContent>
        <DialogActions><Button onClick={()=>{setTtip(prev=>{
          let ts={...prev}
          ts={open:false,title:'',text:''}
          return(ts)
        })}}>Close</Button></DialogActions>
      </Dialog>
      </div>
      </Slide>
  );
}

export default AccountOptions;
