import { useEffect } from "react";
import { Grid, Paper, TextField, FormControlLabel, Switch, Select, MenuItem, FormControl, InputLabel ,Slide } from "@material-ui/core";
import './App.css';

function BusinessInfo(props) {

  function handleChange(field,value){
    let newVal=value;
    if(field==='businessPhone'){
      value = value.replace(/\D/g,'');
      newVal = '';
      for (var i = 0; i < value.length; i++) {
        if(i===0){newVal=newVal+`(${value[i]}`}
        else if(i<=2){newVal=newVal+value[i]}
        else if(i===3){newVal=newVal+`) ${value[i]}`}
        else if(i<=5){newVal=newVal+value[i]}
        else if(i===6){newVal=newVal+`-${value[i]}`}
        else if(i<=9){newVal=newVal+value[i]}
      }
    }
    props.setState(prev=>{
      let ts = {...prev}
      ts[field]=newVal;
      if(field==='businessPhoneSameAsMobile'){
          if(value){document.getElementById('businessPhone').value=ts.mobile}
          else{document.getElementById('businessPhone').value=ts.businessPhone}
      }
      return ts;
    })
  }

  useEffect(()=>{},[props.state])

  return (
      <Slide direction={props.state.direction} in={true}>
      <div className="app-container">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className="card card-full">
            <h2>Tell us about your business</h2>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField style={{width:"100%"}} placeholder="Enter business or employer name"  autoComplete="organization"
                helperText={props.state.formErrors['businessName']?props.state.formErrors['businessName']:''}
                error={props.state.formErrors['businessName']?true:false}
                label="Business Name" required variant="outlined" size="small" defaultValue={props.state.businessName} onChange={(e)=>{handleChange('businessName',e.target.value)}}/>
              </Grid>
              {/* ------------------- */}
              <Grid item xs={8}>
                <TextField id="businessPhone" 
                helperText={props.state.formErrors['businessPhone']?props.state.formErrors['businessPhone']:''}
                error={props.state.formErrors['businessPhone']?true:false}
                style={{width:"100%"}} label="Business Phone" required variant="outlined" size="small" disabled={props.state.businessPhoneSameAsMobile} value={props.state.businessPhoneSameAsMobile?props.state.mobile:props.state.businessPhone} defaultValue={props.state.businessPhoneSameAsMobile?props.state.mobile:props.state.businessPhone} onChange={(e)=>{handleChange('businessPhone',e.target.value)}}/>
              </Grid>
              <Grid item xs={4} style={{paddingTop:'0'}}>
                <FormControlLabel
                    control={
                    <Switch
                        checked={props.state.businessPhoneSameAsMobile}
                        onChange={(e)=>{
                            handleChange('businessPhoneSameAsMobile',e.target.checked)
                        }}
                        color="primary"
                    />
                    }
                    label="Use Mobile"
                    labelPlacement="bottom"
                />
              </Grid>
              {/* ------------------- */}
              <Grid item xs={12}>
                <FormControl style={{width:'100%'}} variant="outlined"
                error={props.state.formErrors['businessType']?true:false}>
                    <InputLabel id="demo-simple-select-outlined-label">Business Type</InputLabel>
                    <Select style={{textAlign:'left'}}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={props.state.businessType}
                    onChange={(e)=>{handleChange('businessType',e.target.value)}}
                    label="Business Type"
                    >
                    <MenuItem value={'Building Construction'}>Building Construction</MenuItem>
                    <MenuItem value={'Painting'}>Painting</MenuItem>
                    <MenuItem value={'Trade Professional'}>Trade Professional</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                </FormControl>
                {props.state.formErrors['businessType']?(<div className="helper-text">{props.state.formErrors['businessType']}</div>):''}
              </Grid>
            </Grid>
            
            </Paper>
          </Grid>
      </Grid>
      </div>
      </Slide>
  );
}

export default BusinessInfo;
